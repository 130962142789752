import React, { useState } from "react";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
    <LessonLayout
      current_step={9}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 9</H1>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={false}
            setFullScreen={setStudentFullScreen}
          >
            <p>There is another type of product sold in stores. These are called supplements. Supplements are defined as products taken by mouth that contain a "dietary ingredient." Dietary ingredients are things like vitamins, minerals, herbs, and plant products. Supplements can come in many forms, such as pills, tablets, capsules, powders, energy bars, or liquids. Supplements are sold in stores and through the internet.</p>
            <p>Some supplements may help people, but, like other products, there can be risks. There are times when supplements can be harmful to some people. Sometimes, people think that because a product is natural, it is safe. Even supplements derived from plants can have an unwanted side effect if used improperly.</p>
            <p>The FDA does <em>not</em> review dietary supplements for safety or effectiveness. The companies that make the supplements are supposed to make sure they are safe and made in a quality manner. The FDA can step in if it is found that a supplement puts people at risk or if it is not what it claims to be.</p>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={false}
            setFullScreen={setTeacherFullScreen}
          >
            <p>Dietary supplements are another class of product. Students may be surprised to learn that these products are not regulated by the FDA before they go on the market. A common misconception is that products that are "natural," such as herbal remedies, are always safe. There have been cases where a natural product can cause problems such as bleeding or interfering with anesthesia during surgeries. It can also be a problem knowing the quantity of the ingredient. There have been cases in which none of the named ingredient can be present in the supplement. The quality of the ingredients can also be variable. (This has been found to be the case with some CBD supplements.) People should discuss the use of dietary supplements with their health care professional.</p>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

